import axios from 'axios'
import {
  IAdvert,
  IAdvertCard,
  IMatch,
  IMatchAdvertFull,
  IMatchCandidateBase,
  IMatchCandidateFull
} from 'data-transfers/dto'
import {
  IGetJoinAdvertsRequest,
  IMatchingCandidatesRequest,
  IMatchingCandidatesSwiperRequest,
  IMatchingRequest,
  IMatchRequest,
  IPossibleFiltersRequest
} from 'data-transfers/requests'
import { IUpdateMatchingStatusRequest } from 'data-transfers/requests/update-matching.request'
import {
  IMatchingAdvertFilterResponse,
  IPaginated,
  IPaginatedResponse
} from 'data-transfers/responses'
import { AdvertStatus, CandidateStatus } from 'enums'

import { authClient, parseContentRange } from 'utils'

type MatchStatusResponse<T extends 'advert' | 'candidate'> = T extends 'advert'
  ? AdvertStatus
  : T extends 'candidate'
    ? CandidateStatus
    : never

const whoStatusRequest = {
  candidate: CandidateStatus,
  advert: AdvertStatus
}

export const getAdvertsMatching = async ({
  candidateId,
  languageCode = 'en',
  ...params
}: IMatchingRequest): Promise<IPaginated<IAdvertCard>> => {
  const { data, headers } = await authClient.get<IAdvertCard[]>(
    `/matches/candidates/${candidateId}/adverts`,
    {
      params: {
        languageCode,
        ...params
      }
    }
  )

  const pagination = parseContentRange(headers)
  return {
    data,
    pagination
  }
}

export const getAdvertsMatchesByCandidateIdSwiper = async ({
  candidateId,
  languageCode = 'en',
  offset = 0,
  ...rest
}: IMatchingRequest): Promise<IPaginatedResponse<IMatchAdvertFull>> => {
  const { data, headers } = await authClient.get<IMatchAdvertFull>(
    `/matches/candidates/${candidateId}/adverts/swiper`,
    {
      params: {
        ...rest,
        offset,
        languageCode
      }
    }
  )

  const pagination = parseContentRange(headers)

  return { ...data, pagination }
}

export const updateMatchingStatus = async <T extends 'advert' | 'candidate'>({
  candidateId,
  advertId,
  advertStatus,
  who
}: IUpdateMatchingStatusRequest<T>) => {
  const path =
    who === 'advert'
      ? `/matches/adverts/${advertId}/candidates/${candidateId}`
      : `/matches/candidates/${candidateId}/adverts/${advertId}`

  const statuses = whoStatusRequest[who]
  const { data } = await authClient.patch<MatchStatusResponse<T>>(
    path,
    statuses[advertStatus]
  )

  return data
}

export const getCandidateMatchesByAdvertId = async ({
  advertId,
  languageCode = 'en',
  limit = 100,
  offset = 0,
  ...rest
}: IMatchingCandidatesRequest): Promise<IPaginated<IMatchCandidateBase>> => {
  const { data, headers } = await authClient.get<IMatchCandidateBase[]>(
    `/matches/adverts/${advertId}/candidates`,
    {
      params: {
        ...rest,
        offset,
        limit,
        languageCode
      }
    }
  )

  const pagination = parseContentRange(headers)

  return { data, pagination }
}

export const getCandidateMatchesByAdvertIdSwiper = async ({
  advertId,
  languageCode = 'en',
  offset = 0,
  ...rest
}: IMatchingCandidatesSwiperRequest): Promise<
  IPaginatedResponse<IMatchCandidateFull>
> => {
  const { data, headers } = await authClient.get<IMatchCandidateFull>(
    `/matches/adverts/${advertId}/candidates/swiper`,
    {
      params: {
        ...rest,
        offset,
        languageCode
      }
    }
  )

  const pagination = parseContentRange(headers)

  return { ...data, pagination }
}

export const getMatchingAdvertFilter = async ({
  id
}: IPossibleFiltersRequest) => {
  const { data } = await authClient.get<IMatchingAdvertFilterResponse>(
    `/matches/adverts/${id}/filters`
  )

  return data
}

export const getMatchingCandidateFilter = async ({
  id
}: IPossibleFiltersRequest) => {
  const { data } = await authClient.get<IMatchingAdvertFilterResponse>(
    `/matches/candidates/${id}/filters`
  )

  return data
}

export const getJoinMatchAdverts = async ({
  userId,
  languageCode = 'en'
}: IGetJoinAdvertsRequest) => {
  const { data } = await authClient.get<IAdvert[]>(`/matches/${userId}`, {
    params: { languageCode }
  })

  return data
}

export const getMatch = async ({ advertId, candidateId }: IMatchRequest) => {
  try {
    const { data } = await authClient.get<IMatch>(
      `/matches/adverts/${advertId}/candidates/${candidateId}`
    )

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      return null
    }
    throw error
  }
}
